.uploadFile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.hidden-input {
    display: none;
}

.file-name {
    max-width: 60%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
