.summary {
    width: 100%;
    &-category {
        margin-bottom: 5px;
        &:last-child {
            margin-bottom: 0;
        }

        &-name, &-value {
            vertical-align: top;
        }
    }

    &-value {
        &-list {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        &-item {
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.summary-item-name {
    vertical-align: top;
}

.summary-items-value {
    margin-bottom: 5px;
    &:last-child {
        margin-bottom: 0;
    }
}