.InfoBlock {
    padding: 15px;
    background-color: #E0EAFF;
    border: 2px solid #aaaaaa;

    &__smaller-padding {
        padding-top: 10px;
    }

    &__red {
        background-color: #FFE0E0;
    }

    &__yellow {
        background-color: rgba(233, 211, 18, 0.62);
    }

    &.bottomSpace {
        margin-bottom: 20px;
    }

    &--title {
        margin-bottom: 15px;
        &__inside {
            margin-bottom: 5px;
        }
    }

    &--content {
        margin: 0 0 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}