.UserMenu {
    &-error-message {
        white-space: nowrap;
        bottom: -5px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.logout-button {
    display: flex;
    align-items: center;
    width: 100%;
    background: none;
    border: none;
    padding: 0;
    font-size: inherit;
    &:hover {
        cursor: pointer;
    }
}

.settings-link {
    display: flex;
    align-items: center;
}
