.Footer {
    bottom: 0;
    width: 100%;
    height: 40px;
    padding-top: 10px;
    background-color: #fff;
    &-sticky {
        position: sticky;
        z-index: 100;
    }
    &-fixed {
        position: fixed;
    }
}

.inner-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding-right: 15px;
    border-top: 2px solid #000;
}

li.contact {
    display: inline;
    margin-left: 5px;
    list-style: none;
    &:before {
        content: '•';
        margin-right: 5px;
    }
    &:first-child{
        margin-left: 0;
        &:before {
            content: '';
        }
    }
}
