
.AuthForm {
    display: flex;
    flex-direction: column;
    width: 304px;

    &Wrapper {
        margin-top: 25px;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    > * {
        margin-top: 10px;

        &:first-child {
            margin-top: 0;
        }
    }
}

.submit-wrap {
    width: 100%;
}