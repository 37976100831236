.nav-bar {
    display: flex;
    padding: 15px 20px;
    border-bottom: 2px solid #000;
}

.link-block {
    display: flex;
}

.link-home {
    display: flex;
    margin-right: 25px;
}

.personal-block {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.link-tasks {
    margin-right: 25px;
}

.lang-block {
    display: inline-flex;
    width: 63px;
    justify-content: space-between;
    margin-left: 25px;
}
