.AccountSettingsForm {
    label {
        margin-top: 15px;
        margin-bottom: 5px;
        &:first-child {
            margin-top: 0;
        }
    }

    &-submit, &-alert {
        margin-top: 20px;
    }
}