.WarningAboutTermsOfUse {
    display: flex;
    white-space: nowrap;
    justify-content: center;
    font-weight: 700;

    &--inline {
        display: inline;
        margin-right: 5px;
        &:last-child {
            margin-right: 0;
        }
    }

    &-text-wrapper {
        display: flex;
        min-width: 600px;
        flex-wrap: wrap;
        justify-content: center;
    }
}