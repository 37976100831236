.loader {
    display: flex;
    animation: rotation 2s infinite linear;

    &-wrapper {
      display: flex;
      justify-content: center;
    }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}