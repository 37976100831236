.Alert {
    display: flex;
    padding: 15px;
    border: 2px solid #aaaaaa;
    align-items: center;
    color: #111;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    

    &-icon {
        margin-right: 5px;
        font-size: 0;
        line-height: 0;
    }

    &--success {
        background-color: rgba(18, 233, 78, 0.62);
    }

    &--error {
        background-color: #FFE0E0;
    }

    &--margin-bottom {
        margin-bottom: 20px;
    }

    &--fit-content {
        width: fit-content;
    }
}