.ChangeDomain {
    display: flex;
    justify-content: center;

    &-text-wrapper {
        min-width: 600px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}