.ProcessForm {
    padding-bottom: 20px;
}

.ProcessList {
    margin-bottom: 20px;
}

.alert-wrapper {
    margin-bottom: 20px;
}