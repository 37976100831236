.VerifyForm {
    padding-bottom: 20px;
    label {
        margin-bottom: 5px;
    }
    > * {
        margin-top: 10px;
        &:first-child {
            margin-top: 0;
        }
    }
}

.file-info {
    margin-top: 20px;
    &--list {
        table-layout: fixed;
        width: 100%;
    }
    
    &--item {
        font-size: 14px;
    }

    &--name {
        width: 40%;
    }

    &--value {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}