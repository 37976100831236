.PricesSelectsForm {
    padding-bottom: 20px;
    label {
        margin-bottom: 5px;
    }
    > * {
        margin-top: 10px;
        &:first-child {
            margin-top: 0;
        }
    }
}